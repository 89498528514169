*{
  box-sizing: border-box;
}
/* Fonts */
@font-face {
  font-family: "Campton";
  src: local("Campton"),
   url("./fonts/CamptonLight.otf") format("opentype");
 }

 @font-face {
  font-family: "Dosis";
  src: local("Dosis"),
   url("./fonts/Dosis-ExtraBold.ttf") format("truetype");
 }

 @font-face {
  font-family: "NewSpirit";
  src: local("NewSpirit"),
   url("./fonts/NewSpiritMedium.otf") format("opentype");
 }
