#landing{
    background-color: #f6f8f7;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex-direction: row;
}

#landing-info{
    width: 500px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#landing-info p{
    line-height: 28px;
    font-size: 18px;
}
#landing-info h1{
    line-height: 58px;
    font-size: 48px;
}

.app-link{
    height: 70px;
}

#phone{
    height: 75vh;
    max-width: 100vw;
    position: absolute;
    right: 0;
    bottom: 0;
    
}

#phone-container{
    height: 100vh;
    position: relative;
    width: 40vw;
   
}

#landing-btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: unset;
    border: 2px solid #a28f89;
    color: #a28f89;
    padding: 0.7rem;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
}

#frantech-link{
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    text-decoration: underline;
    font-family: "Dosis";
    font-size: 22px;
}

#logo{
    width: 10vw;
    min-width: 100px;
}

#landing-footer{
    display: none;
}

@media only screen and (max-width: 1400px) {
   #landing-info{
       text-align: center;
   }
   #landing{
    justify-content: space-evenly;
   }
  }

@media only screen and (max-width: 1200px) {
    
    #landing{
     justify-content: space-between;
    }
   }

@media only screen and (max-width: 1000px) {
    
    #landing{
        flex-direction: column;
        height: auto;
        padding-top: 4rem;

    }

    #phone-container{
        width: 100vw;
        min-height: 850px;
    }

    #phone{
        width: 100vw;
        object-fit: contain;
        object-position: bottom;
        position: absolute;
        bottom: 220px;
        left: 50px;
        overflow: visible;
    }
    #landing-footer{
        flex-grow: 1;
        background-color: #ef6350;
        width: 100vw;
        min-height: 220px;
        position: absolute;
        bottom: 0;
        display: block;
    }
    #frantech-link{
        color: white;
        bottom: 100px;
    }
}

@media only screen and (max-width: 450px) {
    #phone{
        width: 100vw;
        object-fit: cover;
        object-position: bottom;
        position: absolute;
        bottom: 220px;
        left: 62px;
        overflow: visible;
    }
}
